/*
App.js

description:
  this is the main component or the react app, organizing all the pages into routes using <BrowserRouter/>
*/
//packages
import {BrowserRouter,Routes,Route} from 'react-router-dom'
//pages
import Home from "./pages/Home"
import Resume from "./pages/Resume"
import Programming from "./pages/Programming"
import Engineering from "./pages/Engineering"
import Gamedev from "./pages/Gamedev"
import Post from "./pages/Post"
import Licenses from "./pages/Licenses"
import ScrollToTop from './components/ScrollToTop'
//components
import Navbar from "./components/Navbar"
import {Link} from "react-router-dom"

//styles
import "./styles/styles.css"

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop/>
        <div><Navbar/></div>
        <div className="container">
          <Routes>
            <Route
              path="/"
              element={<Home/>}
            />
            <Route
              path = "/resume"
              element={<Resume/>}
            />
            <Route
              path = "/programming"
              element={<Programming/>}
            />
            <Route
              path = "/engineering"
              element={<Engineering/>}
            />
            <Route
              path = "/gamedev"
              element={<Gamedev/>}
            />
            <Route
              path = "/post/:id"
              element={<Post/>}
            />
            <Route
              path = "/licenses"
              element={<Licenses/>}
            />
          </Routes>
        </div>
        <footer>
          Copyright Owen Ziegler 2024. All rights reserved.
          View <Link to="/licenses" className={'footer-link'}>licenses for third-party software</Link>
        </footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
